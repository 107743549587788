import React, { useState } from 'react';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { createElement } from 'react';
import { useNavigate } from 'react-router-dom';
import useModalStore from 'zustand/zustandstore';
const AlertMsg = ({isModalOpen,setModalOpen,toastobject}) => {
 const navigate=useNavigate()
 const {setModalValue}=useModalStore()
 if(!isModalOpen){
    return
 }
 
  return (
    <>
      {isModalOpen && (
        <div
          id="popup-modal"
          tabIndex="-1"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-lg max-h-full">
            <div className="relative bg-white rounded-lg shadow ">
              <button
                type="button"
                className="absolute top-2 right-2  bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-md w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={()=>{                  
                  setModalOpen(false)
                  setModalValue({isrecordingmodal:false})
                  window.location.reload();
                }}
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-4 md:p-5  text-center">
                <div>
                {createElement(toastobject.icon, {
        style: { width: '60px', height: '60px', color: '#1D2951' }
      })}
                </div>
             
                <div className="mb-2 mt-3  font-sans text-lg font-[400]">
             {toastobject.text1}
                </div>
                {toastobject.text2&&<div className="flex items-center font-sans font-[400] justify-center" style={{ fontSize: '10px' }}>
                   <ErrorOutlineIcon style={{ fontSize: '10px', marginRight: '4px' }} />
              {toastobject.text2}
                </div>}
 
               <div>
 
               </div>
               
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
 
export default AlertMsg;