import React, { useEffect, useState } from "react";
import { Backdrop, Card, CardContent, CircularProgress } from "@mui/material";
import blinkscribelogo from "../../../assets/blinkscribe 4.svg";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../api/api";
import ToastMessage from "utils/muialerts/simplealert";
import arrowleft from '../../../assets/arrowleft.png'
import { useDispatch } from "react-redux";
import { resetSettings } from "../../../redux/store";
import { setProviders } from "../../../redux/store";
import { setVisittype } from "../../../redux/store";
import { resetData } from "../../../redux/store";
import useModalStore from "zustand/zustandstore";
const Verify = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [alertmessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const location = useLocation();
  const { phoneNumber,country_code,orignfrom ,nav} = location.state || {};
  const[isLoading,setIsLoading]=useState(false)
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const[error,setError]=useState('')
  const {setModalValue}=useModalStore()
  const dispatch= useDispatch()
  const handlealertClick = (message, severity) => {
    setAlertMessage(message);
    setSeverity(severity);
    setOpen(true);
  };
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;
if(error){
  setError('')
}
    let newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Move to next input if a number is entered
    if (element.nextSibling && element.value !== "") {
      element.nextSibling.focus();
    }
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleKeyDown = (element, index) => {
    if (element.key === "Backspace") {
      if (otp[index] === "") {
        if (index > 0) {
          const updatedOtp = [...otp];
          updatedOtp[index - 1] = ""; // Clear the previous field
          setOtp(updatedOtp);
          element.target.previousSibling?.focus(); // Optionally focus the previous input field
        }
      } else {
        const updatedOtp = [...otp];
        updatedOtp[index] = ""; // Clear the current field
        setOtp(updatedOtp);
      }
    }
  };

  const submitotpfromForgotpassword=async()=>{
    if (otp.some(digit => digit === "")) {
      setError('Enter All Four Digits');
      return;
  }
  try {
    setIsLoading(true)
    const response = await api.post('/v1/auth/check-otp/forgot_password',{
      "mobilephonenumber":  String(phoneNumber),
      "country_code":country_code,
      "otp": otp.join('')
    })
    if(response.status===200){   
      navigate("/updatepassword", { state: { phoneNumber: phoneNumber,nav:nav, country_code :country_code,pin:otp.join('') } });
      setError("verified")

    }else if(response.status===400){
      setError(response.message)
    }else{
      setError(response.message)
    }

    
  } catch (error) {
    setError('Something went wrong')
    
  }finally{
    setIsLoading(false)
  }

  }



  const submitOtp= async()=>{
  
    if (otp.some(digit => digit === "")) {
      setError('Enter All Four Digits');
      return;
  }
  
    
    try {
      setIsLoading(true)
      const response = await api.post('/v1/auth/verify-otp',{
        "mobilephonenumber": phoneNumber,
        "country_code":country_code,
        "otp": otp.join('')
      })
      if(response.status===200){
        const { auth, access_token, data,id } = response
        sessionStorage.setItem('auth', access_token);
        localStorage.setItem('user',data[0]?.username)
        localStorage.setItem('bs_id',id)
        localStorage.setItem('dp_id',data[0]?.config?.departmentid)
        localStorage.setItem('mytab','ALL')
        dispatch(resetSettings())
        dispatch(setProviders([]))
        dispatch(setVisittype([]))
        dispatch(resetData());
     
        const userresponse = await api.get('/auth/me')
        if (userresponse&& userresponse.config && userresponse.config.user_type) {
          if(userresponse.config.user_type=='BASIC'|userresponse.config.user_type=='PLUS'||userresponse.config.user_type=='BUSINESS'){
            setModalValue({usertype:'BASIC'})
              navigate('/app');
            }else if(userresponse.config.user_type=='PRO'){
              setModalValue({usertype:'PRO',})
                navigate('/landing');
            }


        }else{
          setError("Invalid User Type");
          return
        }
        
       
    
        setError("verified")
      }else if(response.status===400){
        setError(response.message)
      }else{
        setError('Something went wrong')
      }
    } catch (error) {
     if (error.status===400){
        setError(error.response.data.message)
      }
      console.log(error)
    }finally{
      setIsLoading(false)
    }

  }
  const handleforgotpasswordResend= async()=>{
    try {
      
      setIsLoading(true)  
      const response = await api.post('/v1/auth/otp/forgot_password', {
        "mobilephonenumber":phoneNumber,
        "country_code": country_code
      })
    
      if(response.status=='200'){
        handlealertClick('Otp has been sent successfully');
     setError('Otp has been sent successfully')
      }else if(response.status==201){
        handlealertClick(response.message);
          setError(response.message)
      }
      
    } catch (error) {
      console.log(error)
      handlealertClick('Something Went Wrong','error');
      
    }finally{
      setIsLoading(false)
    }
  }
  
const handleResendotp= async()=>{
  try {
    
    setIsLoading(true)  
    const response= await api.post('/v1/auth/otp/checkphone',{
      "mobilephonenumber": phoneNumber,
      "country_code":country_code
    })
  
    if(response.status=='200'){
      handlealertClick('Otp has been sent successfully');
   setError('Otp has been sent successfully')
    }else if(response.status==201){
      handlealertClick('Mobile-phone is not registered','error');
        setError('Mobile-phone is not registered')
    }
    
  } catch (error) {
    console.log(error)
    handlealertClick('Something Went Wrong','error');
    
  }finally{
    setIsLoading(false)
  }
}
  return (
    <div className="flex justify-around items-center min-h-screen h-auto p-0 md:p-6 sm:p-6">
      <Card
        className="w-full bg-white sm:w-[40%] md:w-[40%] max-w-lg md:max-w-2xl p-0 md:p-6 sm:p-6 shadow-none sm:shadow-lg md:shadow-lg md:bg-white sm:bg-white h-screen sm:h-[500px] md:h-[500px] sm:rounded-lg md:rounded-2xl"
      >
        <CardContent className="h-full">
        <div className="flex items-start ">
            <img
              onClick={() => {
                navigate("/auth1");
              
              }}
              src={arrowleft}
              alt="Down Arrow"
              className="w-5 h-5 cursor-pointer"
            />
          </div>
          <div className="flex flex-col items-center justify-around h-full">
            <div className="flex justify-center mt-8 md:mt-0 sm:mt-0">
              <img
                src={blinkscribelogo}
                alt="BlinkScribe Logo"
                className="h-24 sm:h-16 md:h-16 w-30 sm:w-auto md:w-auto"
              />
            </div>

            {/* heading */}
            <div>
              <div className="font-semibold text-[#1c2a3a] text-2xl md:text-xl sm:text-xl tracking-[0] leading-9 whitespace-nowrap">
                One Time Passcode
              </div>
              <div className="w-[290px] text-wrap font-normal text-gray-500 text-[15px] tracking-[0] leading-[22.5px] whitespace-nowrap">
                Enter the code <br /> we just sent you to your phone number
              </div>
            </div>

            {/* OTP Input Boxes */}
            <div className="flex justify-center gap-2 w-[90%] md:w-[70%] sm:w-[70%]">
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  className="w-12 h-12 md:w-10 md:h-10 bg-transparent p-3 rounded-lg outline-none border border-gray-300 text-center text-gray-800 text-xl font-normal"
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onFocus={(e) => e.target.select()}
                />
              ))}
            </div>
            <div
  className={`${
    error === "Otp has been sent successfully" ? "text-green-600" : "text-red-600"
  }`}
>
  {error}
</div>

            <div className="flex flex-col items-center space-y-4 w-full mb-24 md:mb-0 sm:mb-0">
              {/* Verify Button */}
              <button
                onClick={() => {
                  if(orignfrom=='otp-login'){
                submitOtp()
                  }else if(orignfrom=='forgotpassword'){
                  submitotpfromForgotpassword()

                  }
                }}
                className="flex w-[90%] md:w-[70%] sm:w-[70%] h-15 sm:h-10 md:h-10 items-center justify-center gap-2.5 px-6 py-4 rounded-full bg-black text-white transition duration-300 ease-in-out"
              >
                <div className="font-semibold text-base">Verify</div>
              </button>
            </div>

            <div>
              <p className="[font-family:'Inter-Regular',Helvetica] font-normal text-transparent text-[15px] text-center tracking-[0] leading-[22.5px]">
                <span className="text-gray-500">Don’t get the code?</span>
                <span className="text-gray-500 text-sm leading-[21px]">&nbsp;</span>
                <span
                onClick={()=>{
                  if(orignfrom=='otp-login'){
                    handleResendotp()
                      }else if(orignfrom=='forgotpassword'){
                     handleforgotpasswordResend()
    
                      }
                  
                 }}
                  style={{ cursor: "pointer" }}
                  className="[font-family:'Inter-Medium',Helvetica] font-medium text-[#1c64f2] text-sm leading-[21px]"
                >
                  Resend
                </span>
              </p>
            </div>

            <div>
              {/* <div
                className="font-semibold text-base text-center cursor-pointer"
                style={{ color: "#0F172A", fontSize: "15px", fontWeight: "500", lineHeight: "150%" }}
              >
                Need Help?
              </div> */}
            </div>
          </div>
        </CardContent>
      </Card>
      <ToastMessage message={alertmessage} severity={severity} open={open} handleClose={handleClose} />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Verify;
