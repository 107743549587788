import logo from '../../../assets/blinkscribe.png';

const ItemView = ({ data, defaulttemp,selectedTemplate, documentKey,setSelectedTemlate }) => {
    if (!data || typeof data !== 'object' || Array.isArray(data)) {
        return (
            <div className="flex flex-col items-center justify-center w-full p-4 h-[100%]">
                <p className="text-red-500 text-lg font-semibold">
                    Something went wrong. Please provide valid data.
                </p>
            </div>
        );
    }
    const renderData = (data) => {
        return Object.entries(data).map(([key, value]) => (
            <div key={key} className="flex flex-col text-start mb-4 w-full">
                <h3 className="font-semibold text-xs mb-1 underline">
                    {key.replace(/_/g, ' ')}:
                </h3>
                {typeof value === 'object' && value !== null ? (
                    Array.isArray(value) ? (
                        // If value is an array, iterate through the elements
                        <ul className="pl-4 list-disc text-xs text-gray-600">
                            {value.length > 0 ? (
                                value.map((item, index) => (
                                    <li key={index} className="break-words">
                                        {typeof item === 'object'
                                            ? renderData(item)
                                            : item || 'No content available'}
                                    </li>
                                ))
                            ) : (
                                <li>No content available</li>
                            )}
                        </ul>
                    ) : (
                        // If value is a nested object, call renderData recursively
                        <div className="pl-4 border-l-2 border-gray-300">
                            {renderData(value)}
                        </div>
                    )
                ) : (
                    // For primitive values, just render them
                    <p className="text-xs text-gray-600 break-words overflow-hidden max-w-full">
                        {value || 'No content available'}
                    </p>
                )}
            </div>
        ));
    };
    return (
       <div
  onClick={() => setSelectedTemlate(documentKey)}
  className={`flex flex-col items-start w-full max-w-[600px] h-[500px] p-4 border 
    ${defaulttemp 
      ? 'shadow-[0px_0px_8px_8px_rgba(133,_182,_255,_0.35)]' 
      : selectedTemplate === documentKey 
        ? '' 
        : 'shadow-md'} 
 border-gray-300 rounded-lg overflow-hidden`}
  style={{
    boxShadow: selectedTemplate === documentKey 
      ? '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 17px 4px rgba(162, 198, 253, 0.75)'
      : undefined,
  }}
>

            {/* Header with Logo */}
            <div className="flex items-center justify-center w-full">
                <img src={logo} alt="logo" className="h-6" />
            </div>

            {/* Horizontal Line */}
            <div className="w-full mt-4">
                <hr className="w-full border-t-2 border-gray-300 my-2" />
            </div>

            {/* Scrollable Content */}
            <div className="flex flex-col items-start justify-start w-full mt-4 overflow-y-auto h-auto custom-scrollbar">
                {Object.keys(data).length === 0 ? (
                    <p className="text-gray-500 text-sm text-center">No data available.</p>
                ) : (
                  
                   <>{renderData(data)}</>
                )
                
                }
            </div>

            {/* Footer */}
            <div className="mt-6 w-full flex flex-col items-center">
                <div className="text-[8px] font-normal [font-family:'Poppins-Medium',Helvetica] text-black tracking-[0] leading-[normal] whitespace-nowrap text-center">
                    Generated By
                </div>
                <img src={logo} alt="logo" className="h-5" />
            </div>
        </div>
    );
};

export default ItemView;
