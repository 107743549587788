import { useState } from "react";
import Flicking from "@egjs/react-flicking";
import { Perspective } from "@egjs/flicking-plugins";
import ItemView from "./itemview";
import BasicDocView from "../basicdocView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const FlickingCor = ({ cards, default_template, selectedTemplate, setSelectedTemlate, mydata }) => {
  const plugins = [new Perspective({ rotate: 1, scale: 1, perspective: 1000 })];
  const [flickingRef, setFlickingRef] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const DOTS_PER_PAGE = 5;
  const totalPages = Math.ceil(cards.length / DOTS_PER_PAGE);

  // Determine the current group of dots to show
  const getDotPage = (index) => Math.floor(index / DOTS_PER_PAGE);
  const currentPage = getDotPage(activeIndex);
  const visibleDots = cards.slice(
    currentPage * DOTS_PER_PAGE,
    (currentPage + 1) * DOTS_PER_PAGE
  );
  const handlePrev = () => {
    if (flickingRef) {
      flickingRef.prev().catch(() => {
        // Optional: Handle edge cases if the carousel can't move
      });
    }
  };

  const handleNext = () => {
    if (flickingRef) {
      flickingRef.next().catch(() => {
        // Optional: Handle edge cases if the carousel can't move
      });
    }
  };

  const handleMove = (e) => {
    setActiveIndex(e.panel.index);
  };
  

  return (
    <div className="w-full relative">
      {/* Flicking carousel container */}
      <Flicking
        circular={true}
        plugins={plugins}
        horizontal={true}
        align="center"
        // gap={20}
        // gap={10}
        className="flicking-container"
        ref={setFlickingRef}
        onChanged={handleMove}
      >
        {cards.map((card, index) => {
          const documentKey = Object.keys(card)[0];
          const documentData = card[documentKey];

          return (
            <div
              key={index}
              className="flicking-slide w-full flex justify-center items-center flex-col sm:w-[500px] h-full p-4"
            >
              {documentKey === default_template ? (
                <div className="text-xs mb-2 opacity-50">Default</div>
              ) : (
                <div className="text-xs mb-2 opacity-50">
                  {documentKey === "HnP"
                    ? "History & Physical"
                    : documentKey === "soap_note"
                    ? "SOAP Note"
                    : documentKey}
                </div>
              )}

              {mydata.usertype === "BASIC" ? (
                <BasicDocView
                  setSelectedTemlate={setSelectedTemlate}
                  documentKey={documentKey}
                  selectedTemplate={selectedTemplate}
                  data={documentData}
                  defaulttemp={documentKey === default_template}
                />
              ) : (
                <ItemView
                  setSelectedTemlate={setSelectedTemlate}
                  documentKey={documentKey}
                  selectedTemplate={selectedTemplate}
                  data={documentData}
                  defaulttemp={documentKey === default_template}
                />
              )}
            </div>
          );
        })}
      </Flicking>

      {/* Navigation icons and dots */}
      <div className="absolute left-1/2 transform -translate-x-1/2 flex items-center gap-4 z-10">
        <button className="p-2  text-[#333333]" onClick={handlePrev}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        {/* Dots */}
        <div className="flex gap-2">
          {visibleDots.map((_, index) => {
            const dotIndex = currentPage * DOTS_PER_PAGE + index;
            return (
              <div
                key={dotIndex}
                className={`w-4 h-2 rounded-full ${
                  dotIndex === activeIndex ? "bg-black" : "bg-gray-300"
                }`}
              ></div>
            );
          })}
        </div>

        <button className="p-2 text-[#333333]" onClick={handleNext}>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>

      <style jsx>{`
        .flicking-container {
          position: relative;
          overflow: hidden;
    
        }
        .flicking-slide {
          position: relative;
          transform-style: preserve-3d;
        }
      `}</style>
    </div>
  );
};

export default FlickingCor;
