import { useState } from 'react';
import blinkscribelogo from '../../../assets/blinkscribe 4.svg';
import { useNavigate } from 'react-router-dom';
import AppOptions from 'general/overlayscreens/appoptions';

const Auth = () => {
  
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
const navigate=useNavigate()
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setIsDropdownOpen(false);
  };

  return (
    <>
      <div className="flex justify-center items-center h-screen">
        {/* Card Container */}
        <div className="w-full h-full sm:w-[60%] md:w-[70%] lg:w-[40%] sm:h-[500px] lg:h-[500px] md:h-[600px] max-w-lg md:max-w-2xl p-6 shadow-none sm:shadow-lg md:shadow-lg rounded-lg bg-white sm:bg-white md:bg-white">
        <div className="flex flex-col justify-between h-[100%]">
        
            <div className="flex justify-center pt-40 md:pt-0 sm:pt-0">
              <img src={blinkscribelogo} alt="BlinkScribe Logo" className="h-32 md:h-16 w-auto" />
            </div>

            
            <div className="flex flex-col items-center ">
            <div className="flex flex-col items-center space-y-4 w-full mb-8 ">
              <button
                onClick={() =>{                    
                     
                    navigate('/auth1')}}
                className={`flex w-[100%] sm:w-[60%] md:w-[60%] h-15 md:h-10 sm:h-10 items-center justify-center gap-2.5 px-6 py-4 rounded-full 
                  bg-black text-white border border-neutral-500
                  transition duration-300 ease-in-out`}
              >
                <div className="font-semibold text-base">Log in</div>
              </button>

              {/* Create Account Button */}
              {/* <button
    onClick={()=>navigate('/sign-up')}
                className={`flex w-[100%] sm:w-[60%] md:w-[60%] h-15 md:h-10 sm:h-10 items-center justify-center gap-2.5 px-6 py-4 rounded-full border border-neutral-500
                
                  transition duration-300 ease-in-out`}
              >
                <div className="font-semibold text-base">Legacy Login</div>
              </button> */}
   

           </div>
           <div className="w-full mt-4 md:mt-8 sm:mt-8 flex flex-col items-center ">
  <div className="mb-4 text-sm text-gray-600 text-start ">
    <span className='w-full text-start'>Have trouble logging in?</span>
  </div>

  <button
   onClick={() => window.location.href = 'https://blinkscribe.com/blinkscribelegacy'}
    className={`flex w-[100%] sm:w-[60%] md:w-[60%] h-15 md:h-10 sm:h-10 items-center justify-center gap-2.5 px-6 py-4 rounded-full border border-neutral-500
                transition duration-300 ease-in-out`}
  >
    <div className="font-semibold text-base">Legacy Login</div>
  </button>
</div>

              {/* <div className="mt-4 md:mt-4 sm:mt-4 flex flex-col items-center text-center">
                <div className="font-semibold text-base" style={{ color: "#0F172A", fontSize: "15px", fontWeight: "500", lineHeight: "100%" }}>
                  Change Language
                </div>

                <div className="relative mt-4 md:mt-2 sm:mt-2">
                  <button
                    onClick={toggleDropdown}
                    className="w-44 h-9 bg-white rounded-lg border border-solid border-[#1c2a3a] flex items-center justify-between px-4"
                  >
                    <span>{selectedLanguage}</span>
                    <svg className="w-4 h-4" viewBox="0 0 24 24">
                      <path d="M7 10l5 5 5-5H7z" fill="currentColor" />
                    </svg>
                  </button>

                  {isDropdownOpen && (
                    <div className="absolute mt-2 w-48 p-2 bg-white border border-solid border-[#1c2a3a] rounded-lg shadow-lg z-10">
                      <ul>
                        <li onClick={() => handleLanguageChange('English')} className="px-4 py-2 text-left hover:bg-gray-100 cursor-pointer">English</li>
                      
                      </ul>
                    </div>
                  )}
                </div>
              </div> */}

            </div>

            {/* Need Help Section */}
            <div className=" mb-8 md:mb-8 sm:mb-8">
              {/* <div
                className="font-semibold text-base text-center cursor-pointer"
                style={{ color: "#0F172A", fontSize: "12px", fontWeight: "500", lineHeight: "150%" }}
              >
                Need Help?
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className='w-full'>
       {/* <AppOptions/>  */}
      </div>
      
    </>
  );
};

export default Auth;
