import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import ToastMessage from 'utils/muialerts/righttoptoast';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import DownloadIcon from '@mui/icons-material/Download';
import CopyAllIcon from '@mui/icons-material/CopyAll';
const PdfRender = ({ ispdfshow, setIspdfShow, pdfBlob,data}) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const[hideoptions,setHideOptions]=useState(false)
  const[loading,setLoading]=useState(false)
  const navigate=useNavigate()
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [message, setMessage] = useState('');
 const [severity, setSeverity] = useState('success');
  const handleZoomIn = () => setZoomLevel(prev => prev + 0.1);
  const handleZoomOut = () => setZoomLevel(prev => Math.max(prev - 0.1, 0.1));
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
};

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const handleNextPage = () => {
    if (currentPage < numPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };
const handleSignoff=async()=>{
  if(loading)return
  setLoading(true)
  try {
    const response= await api.put('/v1/document/sign-off',{"document_name":data?.audiolink})
    if(response.status===200){
      setMessage('Signed!');
      setSeverity('success'); // Change severity as needed
      setHideOptions(true)
      setOpen(true);
    }else if(response.status===201){
      setMessage(response.message);
      setSeverity('info'); // Change severity as needed
      setOpen(true);    
    }
  } catch (error) {
    console.log(error)
    setMessage('something went wrong');
    setSeverity('error'); // Change severity as needed
    setOpen(true);  
  }finally{
    setLoading(false)
  }

}
const handleCopyall=async()=>{
  try {

    const response = await api.get(`/v1/recording/note/${data?.id}/get`);
    if (response.status === 200) {
        const initialData =response?.data[0]?.note_json
        let textToCopy = '';

        // Iterate over the fields object and format each key-value pair as 'key: value'
        for (const key in initialData) {
          if (initialData.hasOwnProperty(key)) {
            textToCopy += `${key}: ${initialData[key]}\n`;
          }
        }
        navigator.clipboard.writeText(textToCopy).then(() => {
          // handlealertClick('Copied to Clipboard')
          setMessage('Copied to Clipboard');
          setSeverity('success');
        
          setOpen(true);
        }).catch(err => {
          console.error('Failed to copy text: ', err);
          setMessage('Something went wrong');
          setSeverity('error');
          setOpen(true);
        });

    }
  } catch (error) {
    
  }finally{

  }
}
const handlePrint = () => {
  if (!pdfBlob) return;

  const blobURL = URL.createObjectURL(pdfBlob);
  const newWindow = window.open(blobURL, '_blank');

  if (newWindow) {
    newWindow.focus();
    newWindow.print();
  } else {
    alert('Unable to open print preview. Please check your browser settings.');
  }
};
const handleDownload = () => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(pdfBlob);
  link.download = `${data?.document_name || 'document'}.pdf`; // Provide a default name
  link.click();
};
const handleShare = () => {
  if (navigator.share) {
    navigator.share({
      title: data?.document_name,
      url: window.location.href,
    });
  } else {
    // Fallback for unsupported browsers
    alert('Sharing is not supported in this browser.');
  }
};
 if (!ispdfshow) return null;

  return (
    <div className="fixed flex flex-col inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
       {data?.transcriptionstatus==='Summarized'&&(!hideoptions)?
 <div className="p-4 mb-3  bg-white shadow-lg w-11/12 max-w-3xl relative rounded-lg gap-4 flex justify-center">
        <button
      onClick={()=>{handleSignoff()}}
      disabled={loading} // Disable the button during loading
      className={`px-4 py-2 min-w-lg text-white rounded-lg text-sm font-semibold transition-all duration-200 ${
        loading ? 'bg-gray-500 cursor-not-allowed opacity-50' : 'bg-[#1C2A3A] cursor-pointer opacity-100'
      }`}
    >
      {loading ? 'Signing Off...' : 'Sign Off'}
    </button>
          <button
            onClick={() =>{
              setMessage('');
       
              setOpen(false);
              setIspdfShow(false)}}
          className="px-4 py-2 min-w-lg bg-[#1C2A3A] text-white rounded-lg text-sm font-semibold">Cancel</button>
          <button
          onClick={()=>{ navigate(`/archive-edit/${data?.id}/${data?.patient_name}`);}}
          className="px-4 py-2 min-w-lg bg-[#1C2A3A] text-white rounded-lg text-sm font-semibold">Edit</button>
        </div> :<><div className="p-4 mb-3 bg-white shadow-lg w-11/12 max-w-3xl relative rounded-lg flex justify-center items-center">
  <p className="text-gray-800 font-semibold text-lg">
  ✅ Signed.
  </p>
</div></>}
      <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-3xl relative">
      
        <div className='flex items-center justify-between p-4'
          style={{ background: "#FFF", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.24)" }}
        >
          <div className='text-[#979797] font-sans'>
            SOAP NOTE
          </div>
          
          <div className="flex items-center gap-4">
            <button onClick={handleZoomOut} className= "px-2">-</button>
            <div className="mx-2 text-[#979797] font-sans">{Math.round(zoomLevel * 100)}%</div>
            <button onClick={handleZoomIn} className="px-2">+</button>
          </div>
          <div className="flex items-center gap-4">
          <button onClick={handleCopyall} className="text-gray-600 hover:text-gray-800">
              <CopyAllIcon />
            </button>
            <button onClick={handlePrint} className="text-gray-600 hover:text-gray-800">
              <PrintIcon />
            </button>
            <button onClick={handleDownload} className="text-gray-600 hover:text-gray-800">
              <DownloadIcon />
            </button>
            {/* <button onClick={handleShare} className="text-gray-600 hover:text-gray-800">
              <ShareIcon />
            </button> */}
            <button
              className="text-gray-600 hover:text-gray-800"
              onClick={() => {
                setMessage('');
                setOpen(false);
                setHideOptions(false);
                setIspdfShow(false);
              }}
            >
              <CloseIcon />
            </button>
          </div>
        
        </div>

        <div className="p-4" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
         <Document file={pdfBlob} onLoadSuccess={onDocumentLoadSuccess}>
                   <Page
                     key={`page_${currentPage}`}
                     pageNumber={currentPage}
                     scale={zoomLevel}
                     renderTextLayer={false}
                   />
                 </Document>
        </div>
        <div className="flex justify-between p-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded-lg text-sm font-semibold ${
              currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-[#1C2A3A] text-white'
            }`}
          >
            Previous
          </button>
          <span className="text-gray-700 font-semibold">
            Page {currentPage} of {numPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === numPages}
            className={`px-4 py-2 rounded-lg text-sm font-semibold ${
              currentPage === numPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-[#1C2A3A] text-white'
            }`}
          >
            Next
          </button>
        </div>
      </div>
      <ToastMessage
                open={open}
                message={message}
                severity={severity}
                handleClose={handleClose}
            />
    </div>
  );
};

export default PdfRender;
