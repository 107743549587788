import { useEffect, useState } from "react";
import usericon from '../../assets/user.png';
import CloseIcon from '@mui/icons-material/Close';
import useModalStore from "zustand/zustandstore";
import { FormControlLabel, Switch } from "@mui/material";

const GeneralPatientmodal = ({ isOpen, close,handledelete,updateSelectedTemplate, handleSaveFromModal }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { setModalValue } = useModalStore();
  const [patientid, setPatientId] = useState('');
  const [patientName, setPatientName] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const handlesubmit = () => {
    if (!patientName) {
      setErrorMessage('Please enter Conversation');
      return;
    }
    // if (!/^[A-Za-z\s]+$/.test(patientName)) {
    //   setErrorMessage('Patient name should contain only alphabets');
    //   return;
    // }
    if (!patientid) {
      setErrorMessage('Please enter Note');
      return;
    }
    
    // setModalValue({ patId: patientid, patName: patientName });
    // updateSelectedTemplate(selectedTemplate)
    handleSaveFromModal(patientid, patientName);
    setPatientId('');
    setPatientName('');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-4 sm:p-6 min-h-[50vh] rounded-[15px] shadow-lg w-[90%] sm:w-[70%] max-w-md relative flex flex-col">
        <button
          onClick={() => {
            setPatientId('');
            setPatientName('');
            close(false);
          }}
          className="absolute top-2 right-2 text-gray-600 hover:text-black"
        >
          <CloseIcon />
        </button>
        <div className="flex flex-col items-center mt-5 justify-between flex-grow">
          <div className="mb-4 text-center">
            <div className="font-bold text-[#1C2A3A] text-lg sm:text-xl leading-7">Add Details</div>
          </div>

 <div className=" mb-4 flex flex-col  w-[80%] mx-auto">
 <label className="text-start text-md w-full font-semibold mb-2">Name</label>
   <div className="">
         
            <input
              type="text"
              value={patientName}
              onChange={(e) => {
                if (errorMessage) setErrorMessage('');
                setPatientName(e.target.value);
              }}
              placeholder="Enter Conversation Name"
              className="w-full bg-transparent h-[45px] border border-gray-300 p-3  rounded-lg outline-none text-gray-800 text-sm font-normal"
            />
            {/* <img src={usericon} alt="User Icon" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500" /> */}
          </div></div>
      
          {/* Patient ID Text Area with Note */}
          <div className=" mb-4 w-[80%] flex flex-col mx-auto">
            <label className="text-start text-md w-full font-semibold mb-2">Notes</label>
            <textarea
              value={patientid}
              onChange={(e) => {
                if (errorMessage) setErrorMessage('');
                setPatientId(e.target.value);
              }}
              placeholder=""
              rows="6"
              className="w-full bg-transparent border border-gray-300 p-3  rounded-lg outline-none text-gray-800 text-sm font-normal resize-none"
            ></textarea>
        
          </div>
          {/* <div className="w-[80%] flex items-center gap-2">
  <select
    defaultValue="basic"
    className="p-2 w-full border border-gray-300 rounded-lg text-sm font-medium text-gray-800 focus:outline-none "
    onChange={(e) => setSelectedTemplate(e.target.value)} 
  >
    <option value="">Basic</option>
    <option value="HnP">HNP</option>
  </select>
</div> */}
          {/* Error Message */}
          {errorMessage && (
            <div className="text-red-500 text-xs sm:text-sm mb-4 w-[80%] mx-auto">
              {errorMessage}
            </div>
          )}

          <div className="flex items-center justify-center mt-4 gap-5 w-full">
            <button
              onClick={() => handlesubmit()}
              className="w-[40%] p-4 h-10 bg-[#1c2a3a] text-white text-sm font-semibold rounded-[14px] flex items-center justify-center"
            >
              Save
            </button>
            <button
              onClick={() => {
                setPatientId('');
                setPatientName('');
                handledelete()
                close(false);
              }}
              className="w-[40%] p-4 h-10 bg-[#1c2a3a] text-white text-sm font-semibold rounded-[14px] flex items-center justify-center"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralPatientmodal;
