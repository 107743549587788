import React, { useEffect, useRef, useState } from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import RangeSlider from '../../../pages/landing/rangeslider'; // Assuming you have a RangeSlider component
import Box from '@mui/material/Box';
import Calendar from 'react-calendar'; // Import the calendar component
import 'react-calendar/dist/Calendar.css'; // Import the calendar styles
import { useDispatch, useSelector } from 'react-redux';
import { formatDateToYMD } from 'utils/date';
import { decodeTimeToISO } from 'utils/date';
import { setSavedload, setProviders, setVisittype } from "../../../redux/store";
import { parseISO, startOfDay } from 'date-fns'; // Import from date-fns
import { toZonedTime } from 'date-fns-tz'; // Only if you need this for timezone
import { Tooltip } from '@mui/material';
import useModalStore from 'zustand/zustandstore';
import { DateRange } from 'react-date-range';
const storedDate=(x)=>{  
  let normaldate=[{
    startDate: new Date(),
    endDate: new Date(), 
    key: "selection",
  }]
   const dateFilter = x?.filter_array?.date_filter;   
   if (!dateFilter || !dateFilter.start_date || !dateFilter.end_date) {  

    return normaldate
  }else{

   let changed=[{
    startDate: startOfDay(parseISO(dateFilter.start_date)), // Use parseISO from date-fns
    endDate: startOfDay(parseISO(dateFilter.end_date)), 
    key: "selection",
  }]
    return changed
  }
}
const convertTimeToValue = (timeString) => {
    if(!timeString){
      return 420
    }
    // Extract hours and minutes from the time string
    const timeParts = timeString.split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
  
    // Convert to total minutes
    return hours * 60 + minutes;
  };
const Filtersdt = ({handletimeStamp,}) => {
    const savedload = useSelector((state) => state.data.savedload);
     const [showDatePicker, setShowDatePicker] = useState(false);
      const [value, setValue] = useState([420, 1140]);
      const [newdatepicker,setNewDatePicker]=useState(false)
       const [datestate, setdateState] = useState(storedDate(savedload));
       const [hoverCustomDate, setHoverCustomDate] = useState(false);
       const [calendarPosition, setCalendarPosition] = useState("right");
       const [selectedCustomDate, setSelectedCustomDate] = useState(null);
const [showCalendar, setShowCalendar] = useState(false);
       const [disableLi, setDisableLi] = useState([])
        const [dateRange, setDateRange] = useState([
           {
             startDate: new Date(),
             endDate: new Date(),
             key: "selection",
           },
         ]);
       const dropdownRef = useRef(null);
    const dispatch = useDispatch();
    const formatDate = (date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const day = days[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = months[date.getMonth()];
        return `${day}, ${dayOfMonth} ${month}`;
    };
 const mydata=useModalStore()
const handleOnDateSelector=(x)=>{
  let normaldate=[{
    startDate: new Date(),
    endDate: new Date(), 
    key: "selection",
  }]
normaldate[0].startDate=x;
normaldate[0].endDate=x 
setdateState(normaldate) 
handletimeStamp({
  startDate:formatDateToYMD(x),
  endDate:formatDateToYMD(x),
  startTime:decodeTimeToISO(value[0]),
  endTime:decodeTimeToISO(value[1])
 })

 setShowDatePicker(false);

}
const handledateChange = (value) => {
  console.log(value)
  setdateState(value);
};
    // useEffect(()=>{
    //     if(savedload.filter_array?.date_filter.start_time){
    //      setValue([convertTimeToValue(savedload.filter_array?.date_filter.start_time),convertTimeToValue(savedload.filter_array?.date_filter.end_time)])
    //     }       
    
    //   },[savedload])
       
    const changeDate = (days) => { 
      const newDate = new Date(datestate[0].startDate);
        newDate.setDate(newDate.getDate() + days);
        let normaldate=[{
          startDate: new Date(),
          endDate: new Date(), 
          key: "selection",
        }]
      normaldate[0].startDate=newDate;
      normaldate[0].endDate=newDate;        
        setdateState(normaldate)
        handletimeStamp({
          startDate:formatDateToYMD(newDate),
          endDate:formatDateToYMD(newDate),
          startTime:decodeTimeToISO(value[0]),
          endTime:decodeTimeToISO(value[1])
         })
      
    };
    const handleDateSelection = (item) => {
      const today = new Date();
      let startDate, endDate;
      switch (item) {
        case "Today":
          // Set both start and end dates to today
          startDate = new Date(today);
          endDate = new Date(today);
          break;
        case "Yesterday":
          // Set the end date to yesterday
          endDate = new Date(today);
          endDate.setDate(today.getDate() - 1);
          startDate = new Date(endDate); // Set start date to same as end date (yesterday)
          break;
    
        case "Last 7 Days":
          // Set the end date to today
          endDate = new Date(today);
          
          // Set the start date to 7 days before the end date (i.e., last week)
          startDate = new Date(endDate);
          startDate.setDate(endDate.getDate() - 7);
          break;
    
        case "Last 30 Days":
          // Set the end date to today
          endDate = new Date(today);
    
          // Set the start date to 30 days before today
          startDate = new Date(today);
          startDate.setDate(today.getDate() - 30);
          break;
    
        case "Custom Range":
          setNewDatePicker(false)
          setShowCalendar(true);
          return; // Custom date logic handled elsewhere
       case "This Month":
            // Set the start date to the first day of the current month
            startDate = new Date(today.getFullYear(), today.getMonth(), 1);
            // Set the end date to today (if today is within the current month)
            endDate = new Date(today);
            break;
      case "Last Month":
            // Set the start date to the first day of the previous month
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            // Set the end date to the last day of the previous month
            endDate = new Date(today.getFullYear(), today.getMonth(), 0); // Using day 0 of the current month gives the last day of the previous month
            break;
        default:
          return;
      }
    
      // Create a new date object with updated start and end date
      const normaldate = [{
        startDate: startDate,
        endDate: endDate,
        key: "selection",
      }];
    
      // Update datestate[0] with new startDate and endDate
      setdateState(normaldate);
    
      // Update the timeStamp with formatted start and end dates
      handletimeStamp({
        startDate: formatDateToYMD(startDate),
        endDate: formatDateToYMD(endDate),
        startTime: decodeTimeToISO(value[0]),
        endTime: decodeTimeToISO(value[1]),
      });
    
      setNewDatePicker(false);
    };
    


    const handleDateClick = () => {
        setShowDatePicker(!showDatePicker);
    };
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setNewDatePicker(false);
          setHoverCustomDate(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
    const dateOnCloseHandler = () => {
     setdateState(storedDate(savedload)) 
     if(savedload.filter_array?.date_filter.start_time){
      setValue([convertTimeToValue(savedload.filter_array?.date_filter.start_time),convertTimeToValue(savedload.filter_array?.date_filter.end_time)])
     }  
        setShowDatePicker(false);
        setShowCalendar(false)
    };

    
    const dateOnCloseHandle=()=>{     
        handletimeStamp({
      startDate:formatDateToYMD(datestate[0].startDate),
      endDate:formatDateToYMD(datestate[0].startDate),
      startTime:decodeTimeToISO(value[0]),
      endTime:decodeTimeToISO(value[1])
     })

     setShowDatePicker(false);
   
    }

    const daterangeCloseHandle=()=>{ 

    handletimeStamp({
    startDate:formatDateToYMD(datestate[0].startDate),
    endDate:formatDateToYMD(datestate[0].endDate),
    startTime:decodeTimeToISO(value[0]),
    endTime:decodeTimeToISO(value[1])
   })

 setShowCalendar(false)
 
  }
    useEffect(()=>{
      setdateState(storedDate(savedload))
  
  },[savedload])


const handledateClear=()=>{
  handletimeStamp({
    startDate:new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
    startTime: decodeTimeToISO(value[0]),
    endTime: decodeTimeToISO(value[1]),
  });
  setShowDatePicker(false);
}



    return (
        <div className='relative'>
           {mydata.usertype==='BUSINESS'?<div className='flex items-center gap-2'>
              <div onClick={() => changeDate(-1)} className="mx-2" style={{ fontSize: "25px", cursor: "pointer" }}>&lt;</div>
                <div
                    className='p-2 bg-[#FFF] cursor-pointer border w-auto min-w-48'
                    onClick={handleDateClick}
                    style={{
                        borderRadius: "18px",
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                    }}
                >
                    <span className="text-center [font-family:'Poppins-Regular',Helvetica]  text-[#060606] text-sm tracking-[0.14px] leading-[normal]">
                        {formatDate(datestate[0].startDate)}
                    </span>
                </div>
                <div onClick={() => changeDate(1)} className="mx-2" style={{ fontSize: "25px", cursor: "pointer" }}>&gt;</div>
          
            </div>:<div>
  <div
    className='p-2 px-4 bg-[#FFF] cursor-pointer border w-auto min-w-50'
    onClick={() => setNewDatePicker(true)}
    style={{
      borderRadius: "18px",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      display: "flex", // Flexbox to align icon with text
      justifyContent: "space-between", // Space between text and icon
      alignItems: "center", // Align the items vertically
    }}
  >
    <span className="text-center [font-family:'Poppins-Regular',Helvetica] text-[#060606] text-sm tracking-[0.14px] leading-[normal]">
      {formatDate(datestate[0].startDate)===formatDate(datestate[0].endDate)?(formatDate(datestate[0].startDate)):`${formatDate(datestate[0].startDate)} - ${formatDate(datestate[0].endDate)}`}
    </span>
    
    {/* Down Arrow Icon */}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className="ml-2"
    >
      <path
        d="M8.625 4.125L6 6.75 3.375 4.125"
        stroke="#060606"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
</div>

              
              }
{newdatepicker && (
  <div
    ref={dropdownRef}
    className="absolute z-50 bg-white border rounded-lg shadow-lg"
    style={{
      top: "100%",
      right: calendarPosition === "right" ? "0" : "auto",
      left: calendarPosition === "left" ? "0" : "auto",
      minWidth: "200px",
    }}
  >
    <ul className="space-y-2 p-2">
      {["Today","Yesterday", "Last 7 Days", "Last 30 Days","This Month","Last Month", "Custom Range"].map((item, index) => (
          <li
            key={index}
            className={`cursor-pointer text-start p-2 ${
              disableLi.includes(item) ? "text-gray-300 cursor-not-allowed" : "hover:bg-gray-200"
            }`}
            onClick={() => {
              if (!disableLi.includes(item)) {
                handleDateSelection(item);
              }
            }}
          >
            {item}
          </li>
        
      ))}
    </ul>
    
  </div>
)}
{showCalendar && (
  // <div
  //   className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
  //   onClick={(e) => {
  //     // Close overlay if clicking outside the calendar
  //     if (e.target === e.currentTarget) setShowCalendar(false);
  //   }}
  // >
  //   <div className="bg-white p-4 rounded-lg shadow-lg">
  //     <Calendar
  //       onChange={(value)=>{
  //       setShowCalendar(false)
  //       handleOnDateSelector(value)
  //       }} 
  //                           value={datestate[0].startDate}
       
      
  //     />
  //   </div>
  // </div>
    <div
    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    onClick={(e) => {
      // Close overlay if clicking outside the calendar
      if (e.target === e.currentTarget) setShowCalendar(false);
    }}
  >
<div 
className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 bg-white p-4 md:p-2 sm:p-2 rounded-lg shadow-lg">
  {/* Date Picker */}
  <div className='mb-4 flex items-center justify-between'>
    <div className="w-full flex justify-between">
      <div className="[font-family:'Poppins-Medium',Helvetica] font-bold text-black text-base text-center tracking-[0.16px] leading-[normal]">
        Calendar
      </div>
      <button
        className={`p-2 h-6 w-[30%] rounded-[14px] shadow-lg text-[10px] font-semibold flex items-center justify-center transition-colors duration-300 bg-black text-white border border-gray-300`}
      onClick={()=>daterangeCloseHandle()}>Apply</button>
      {/* Cancel Button */}
      <div onClick={() => dateOnCloseHandler()}>
        <CloseIcon style={{ color: "#B4B4B4", cursor: "pointer" }} />
      </div>
    </div>
  </div>
  <DateRange
    editableDateInputs={true}
    onChange={(item) => handledateChange([item.selection])}
    moveRangeOnFirstSelection={false}
    ranges={datestate}
    className="text-xs"
  />
 
</div>
</div>
)}


            {/* Backdrop for dark overlay for date picker */}
            {showDatePicker && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-10" onClick={dateOnCloseHandler} />
            )}

            {/* Modal for date picker */}
            {showDatePicker && (
                <div className="absolute top-[110%] left-[0%] right-[5%] sm:right-0 lg:left-[13%] sm:w-full z-20 bg-white p-4 rounded-lg shadow-lg">
                    {/* <div className='w-full flex justify-between items-center'>
                       
                        <div className='w-full flex items-center justify-center gap-2'>
                            <button onClick={()=>{
                              // handleApplyClick()
                              dateOnCloseHandle()
                            }} 
                           className={`p-2 h-8 w-16 rounded-[14px] shadow-lg text-[10px] font-semibold flex items-center justify-center transition-colors duration-300 bg-black text-white border border-gray-300`}>
                                Apply
                            </button>
                            <button onClick={()=>{
                             handledateClear()
                            }} 
                           className={`p-2 h-8 w-16 rounded-[14px] shadow-lg text-[10px] font-semibold flex items-center justify-center transition-colors duration-300 bg-black text-white border border-gray-300`}>
                                Clear
                            </button>
                            <CloseIcon style={{ color: '#B4B4B4', cursor: 'pointer' }} onClick={dateOnCloseHandler} />
                        </div>
                    </div> */}

                    <Box mt={2}>
                        {/* Integrating the Calendar component */}
                        <Calendar
                            onChange={(value)=>handleOnDateSelector(value)} 
                            value={datestate[0].startDate} // Show selected date
                            className="text-decoration-line: none" // Add custom class if needed
                       
                        />
                    </Box>

                    {/* <div className='flex justify-center mt-4'>
                        <RangeSlider value={value} setValue={setValue} />
                    </div> */}
                </div>
            )}

          
          
             
        </div>
    );
};

export default Filtersdt;
